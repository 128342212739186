// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[5].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js!../colors.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
`, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorRedPink": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["colorRedPink"]}`,
	"actionColor--mycanal": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["colorRedPink"]}`,
	"darkActionColor--mycanal": `#a5263b`,
	"hoverActionColor--mycanal": `#EE4A65`,
	"actionColor--mycanal_tvod": `#93147D`,
	"darkActionColor--mycanal_tvod": `#891175`,
	"hoverActionColor--mycanal_tvod": `#A44794`
};
module.exports = ___CSS_LOADER_EXPORT___;
