var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment, useCallback, useEffect, useRef, useState, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import { omit } from '@canalplus/oneplayer-utils';
import { isMobileSelector, shouldShowExpertModeNotificationSelector, } from '@oneplayer/selectors';
import { showFromHtmlElementAction, hideAction, setContentAction, } from '../actions/TooltipActions';
var durations = require('../assets/styles/durations.css');
var styles = require('./withTooltip.css');
var TOOLTIP_TIMEOUT = 500;
var TOOLTIP_HIDE_TIMEOUT = 5000;
var layoutShiftDuration = parseInt(durations.layoutShiftDuration, 10);
export default function withTooltip(WrappedComponent) {
    return function (wrappedProps) {
        var _a = useState(false), shouldShowTooltip = _a[0], setShow = _a[1];
        var isMobile = useSelector(isMobileSelector);
        var shouldShowExpertModeNotification = useSelector(shouldShowExpertModeNotificationSelector);
        var dispatch = useDispatch();
        var content = wrappedProps.content, tooltipPosition = wrappedProps.tooltipPosition, isDisabled = wrappedProps.isDisabled;
        var tooltipDelay = useRef(null);
        var tooltipHideDelay = useRef(null);
        useEffect(function () {
            if (shouldShowTooltip) {
                // Only update the content in the redux store if the tooltip content of the
                // currently-wrapped component is changing AND it's currently being displayed.
                // Without this second check the content will update much more often than needed.
                // eslint-disable-next-line react/jsx-no-useless-fragment
                var contentToDisplay = _jsx(Fragment, { children: content });
                dispatch(setContentAction(contentToDisplay));
            }
        }, [content, shouldShowTooltip, dispatch]);
        var show = function (e, theme, isNotification) {
            if (isNotification === void 0) { isNotification = false; }
            // Currently we can only show one tooltip at a time, so if the expert mode
            // notification is being displayed we can not show another tooltip.
            if (shouldShowExpertModeNotification && !isNotification) {
                return;
            }
            // eslint-disable-next-line react/jsx-no-useless-fragment
            var contentToDisplay = _jsx(Fragment, { children: content });
            var htmlElement = e instanceof HTMLElement ? e : e.currentTarget;
            if (isNotification) {
                dispatch(showFromHtmlElementAction(contentToDisplay, htmlElement, tooltipPosition, theme));
            }
            else {
                tooltipDelay.current = window.setTimeout(function () {
                    setShow(true);
                    dispatch(showFromHtmlElementAction(contentToDisplay, htmlElement, tooltipPosition, theme));
                }, TOOLTIP_TIMEOUT);
            }
        };
        var onLeave = useCallback(function () {
            if (!shouldShowExpertModeNotification) {
                if (tooltipDelay.current !== null) {
                    clearTimeout(tooltipDelay.current);
                }
                if (tooltipHideDelay.current !== null) {
                    clearTimeout(tooltipHideDelay.current);
                }
                setShow(false);
                dispatch(hideAction());
            }
        }, [
            dispatch,
            shouldShowExpertModeNotification,
            tooltipDelay,
            tooltipHideDelay,
        ]);
        useEffect(function () {
            return function () {
                // Before unmounting the component, we must clear the timeout (if one is set)
                // in order to hide the tooltip correctly.
                onLeave();
            };
        }, [onLeave]);
        var applyDisabledTooltipTheme = function (e) {
            var whiteList = ['expertModeBtPressed'];
            var node = ReactDOM.findDOMNode(e.target);
            if (node instanceof HTMLElement &&
                node.dataset.spAction &&
                whiteList.includes(node.dataset.spAction)) {
                show(node, 'disabled');
            }
        };
        var notifyTooltip = function (ref) {
            var whiteList = [
                {
                    action: 'expertModeBtPressed',
                    notification: shouldShowExpertModeNotification,
                },
            ];
            whiteList.forEach(function (whiteItem) {
                if (whiteItem.notification) {
                    setTimeout(function () {
                        var node = ReactDOM.findDOMNode(ref);
                        if (node instanceof HTMLElement &&
                            ref.dataset.spAction === whiteItem.action) {
                            show(node, 'info', true);
                            tooltipHideDelay.current = window.setTimeout(function () { return dispatch(hideAction()); }, TOOLTIP_HIDE_TIMEOUT);
                        }
                    }, layoutShiftDuration); // wait for sidebar slide-in animation to finish
                }
            });
        };
        // NOTE: We don't pass all the props to the children because they aren't all needed (this will prevent wasted renders)
        var componentProps = omit(wrappedProps, ['content', 'tooltipPosition']);
        if (isMobile) {
            // eslint-disable-next-line react/jsx-props-no-spreading
            return _jsx(WrappedComponent, __assign({}, componentProps));
        }
        return (_jsx("div", { ref: function (r) {
                if (r) {
                    notifyTooltip(r);
                }
            }, onMouseEnter: function (e) {
                if (isDisabled) {
                    applyDisabledTooltipTheme(e);
                }
            }, onMouseLeave: onLeave, className: styles.withTooltip, children: _jsx(WrappedComponent
            // eslint-disable-next-line react/jsx-props-no-spreading
            , __assign({}, componentProps, { onEnter: show, onLeave: onLeave, onMouseEnter: show, onMouseLeave: onLeave })) }));
    };
}
