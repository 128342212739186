// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[5].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js!../../../assets/styles/mycanal/colors.css");
var ___CSS_LOADER_ICSS_IMPORT_1___ = require("-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[5].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js!../../../assets/styles/telecomitalia/colors.css");
var ___CSS_LOADER_ICSS_IMPORT_2___ = require("-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[5].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js!../../../assets/styles/colors.css");
var ___CSS_LOADER_ICSS_IMPORT_3___ = require("-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[5].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js!../../../assets/styles/layout.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_2___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_3___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Block and modifiers
 */

.Xkybc0jZ8gvdpTBJcAu1 {
  z-index: ${___CSS_LOADER_ICSS_IMPORT_3___.locals["spinnerIndex"]};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.Scp3YRiDrN7oJtsS6_He {
  position: absolute;
  right: 5%;
  bottom: 5%;
  z-index: ${___CSS_LOADER_ICSS_IMPORT_3___.locals["spinnerIndex"]};
}

/**
 * Elements
 */

.NV5TVqjy_EpSOwN3vlvx {
  display: block;
  width: 40px;
  height: 40px;
  border-color: ${___CSS_LOADER_ICSS_IMPORT_2___.locals["colorWhite"]} transparent ${___CSS_LOADER_ICSS_IMPORT_2___.locals["colorWhite"]} ${___CSS_LOADER_ICSS_IMPORT_2___.locals["colorWhite"]};
  border-style: solid;
  border-width: 5px;
  border-radius: 50%;
  animation: mdA80pcMTNInaiduqTMT 1s linear infinite;
}

.y9mx5uF4FNMf1lsYq4y4 {
    border-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["actionColor--mycanal"]} transparent ${___CSS_LOADER_ICSS_IMPORT_0___.locals["actionColor--mycanal"]} ${___CSS_LOADER_ICSS_IMPORT_0___.locals["actionColor--mycanal"]};
  }
.go_gNpbcT64l7pPOiz8c {
    border-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["actionColor--mycanal_tvod"]} transparent ${___CSS_LOADER_ICSS_IMPORT_0___.locals["actionColor--mycanal_tvod"]} ${___CSS_LOADER_ICSS_IMPORT_0___.locals["actionColor--mycanal_tvod"]};
  }
.Emoya_PUZt0J1TxDDJAk {
    border-color: ${___CSS_LOADER_ICSS_IMPORT_1___.locals["actionColor--telecomitalia"]} transparent ${___CSS_LOADER_ICSS_IMPORT_1___.locals["actionColor--telecomitalia"]} ${___CSS_LOADER_ICSS_IMPORT_1___.locals["actionColor--telecomitalia"]};
  }

@keyframes mdA80pcMTNInaiduqTMT {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/ui/components/presentational/spinner/Spinner.css"],"names":[],"mappings":"AAOA;;EAEE;;AAEF;EACE,qDAAqB;EACrB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,qDAAqB;AACvB;;AAEA;;EAEE;;AAEF;EACE,cAAc;EACd,WAAiB;EACjB,YAAkB;EAClB,gKAA0D;EAC1D,mBAAmB;EACnB,iBAA+B;EAC/B,kBAAkB;EAClB,kDAAoC;AACtC;;AAGE;IAEE,gKAA8C;EAChD;AAHA;IAEE,gKAA8C;EAChD;AAHA;IAEE,gKAA8C;EAChD;;AAOF;EACE;IACE,oBAAoB;EACtB;;EAEA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":["@value actionColor--mycanal, actionColor--mycanal_tvod from \"../../../assets/styles/mycanal/colors.css\";\n@value actionColor--telecomitalia from \"../../../assets/styles/telecomitalia/colors.css\";\n@value colorWhite from \"../../../assets/styles/colors.css\";\n@value spinnerIndex from \"../../../assets/styles/layout.css\";\n@value masterSize: 40px;\n@value masterBorderWidth: 5px;\n\n/**\n * Block and modifiers\n */\n\n.spinnerCentered {\n  z-index: spinnerIndex;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n}\n\n.spinnerBottom {\n  position: absolute;\n  right: 5%;\n  bottom: 5%;\n  z-index: spinnerIndex;\n}\n\n/**\n * Elements\n */\n\n.circle {\n  display: block;\n  width: masterSize;\n  height: masterSize;\n  border-color: colorWhite transparent colorWhite colorWhite;\n  border-style: solid;\n  border-width: masterBorderWidth;\n  border-radius: 50%;\n  animation: rotate 1s linear infinite;\n}\n\n@define-mixin makeVariant $variant, $color {\n  .circle--$(variant) {\n    composes: circle;\n    border-color: $color transparent $color $color;\n  }\n}\n\n@mixin makeVariant mycanal, actionColor--mycanal;\n@mixin makeVariant mycanal_tvod, actionColor--mycanal_tvod;\n@mixin makeVariant telecomitalia, actionColor--telecomitalia;\n\n@keyframes rotate {\n  from {\n    transform: rotate(0);\n  }\n\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionColor--mycanal": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["actionColor--mycanal"]}`,
	"actionColor--mycanal_tvod": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["actionColor--mycanal_tvod"]}`,
	"actionColor--telecomitalia": `${___CSS_LOADER_ICSS_IMPORT_1___.locals["actionColor--telecomitalia"]}`,
	"colorWhite": `${___CSS_LOADER_ICSS_IMPORT_2___.locals["colorWhite"]}`,
	"spinnerIndex": `${___CSS_LOADER_ICSS_IMPORT_3___.locals["spinnerIndex"]}`,
	"masterSize": `40px`,
	"masterBorderWidth": `5px`,
	"spinnerCentered": `Xkybc0jZ8gvdpTBJcAu1`,
	"spinnerBottom": `Scp3YRiDrN7oJtsS6_He`,
	"circle": `NV5TVqjy_EpSOwN3vlvx`,
	"rotate": `mdA80pcMTNInaiduqTMT`,
	"circle--mycanal": `y9mx5uF4FNMf1lsYq4y4 NV5TVqjy_EpSOwN3vlvx`,
	"circle--mycanal_tvod": `go_gNpbcT64l7pPOiz8c NV5TVqjy_EpSOwN3vlvx`,
	"circle--telecomitalia": `Emoya_PUZt0J1TxDDJAk NV5TVqjy_EpSOwN3vlvx`
};
module.exports = ___CSS_LOADER_EXPORT___;
