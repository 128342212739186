import { BusinessTypes } from '@canalplus/oneplayer-constants';
var _a = BusinessTypes.MODAL_TYPES, AUDIO_ONLY = _a.AUDIO_ONLY, PLAYBACK_RATE = _a.PLAYBACK_RATE, QUALITY_CONTROL = _a.QUALITY_CONTROL, SETTINGS = _a.SETTINGS;
// Toggled Controls: general ---------------------------------
/**
 * @param isCastPending whether chromecast is pending
 * @param isCastActive whether chromecast is active
 * @param isSidepanelOpen whether side panel is open
 * @param isActive whether controls are active
 * @returns whether toggled controls are shown
 */
export var isToggledControlsShown = function (isCastPending, isCastActive, isSidepanelOpen, isActive) { return isCastPending || isCastActive || (!isSidepanelOpen && isActive); };
// Toggled Controls: Sidebar ---------------------------------
/**
 * @param shouldShowToggleControls whether we need to show toggle controls
 * @param shouldShowExpertModeNotification whether we need to show expert Mode notification
 * @param getGroupType number of group type
 * @param modalType type of the modal
 * @returns whether side bar controls are shown
 */
export var isSidebarControlsShown = function (shouldShowToggleControls, shouldShowExpertModeNotification, getGroupType, modalType) {
    return modalType === AUDIO_ONLY ||
        modalType === SETTINGS ||
        modalType === PLAYBACK_RATE ||
        modalType === QUALITY_CONTROL
        ? false
        : (shouldShowToggleControls || shouldShowExpertModeNotification) &&
            getGroupType !== 4;
};
// ContentProgressBar ---------------------------------
/**
 * @param isStopped whether player is stopped
 * @param isProgramReady whether the program is ready
 * @returns whether content progress bar is shown
 */
export var isContentProgressBarShown = function (isStopped, isProgramReady) { return !isStopped && isProgramReady; };
// Airplay ---------------------------------
/**
 * @param isAirplayAvailable whether airplay is available
 * @param isAirplayEnabled whether airplay is enabled
 * @param isCurrentSequenceAirplayEnabled whether current sequence is airplay enabled
 * @param isPremium whether the player is premium
 * @returns whether airplay control is shown
 */
export var isAirplayControlShown = function (isAirplayAvailable, isAirplayEnabled, isCurrentSequenceAirplayEnabled, isPremium) {
    return isAirplayAvailable &&
        isAirplayEnabled &&
        isCurrentSequenceAirplayEnabled &&
        !isPremium;
};
// Cast ---------------------------------
/**
 * @param isCastAvailable whether chromecast is available
 * @param isCastable whether the content is castable
 * @param isCurrentSequenceBookend whether the current sequence is bookend
 * @param isCastActive whether chromecast is active
 * @returns whether chromecast controls is shown
 */
export var isCastControlShown = function (isCastAvailable, isCastable, isCurrentSequenceBookend, isCastActive) {
    return (isCastAvailable && isCastable && (!isCurrentSequenceBookend || isCastActive));
};
// Exit Player ---------------------------------
/**
 * @param isFullscreen whether the player is fullscreen
 * @param showFullscreen whether the exit Button of the fullscreen is shown
 * @param showWindowed whether the exit Button of the window is shown
 * @returns whether the exit Button of the player is shown
 */
export var isExitPlayerControlShown = function (isFullscreen, showFullscreen, showWindowed) {
    return (isFullscreen && showFullscreen) || (!isFullscreen && showWindowed);
};
// Settings ---------------------------------
/**
 * @param shouldShowPlaybackRate whether playbackRate should be shown
 * @param shouldShowAudioOnly whether audioOnly should be shown
 * @param shouldShowVideoQualityControl whether video quality control should be shown
 * @returns whether settings control is shown
 */
export var isSettingsControlShown = function (shouldShowPlaybackRate, shouldShowAudioOnly, shouldShowVideoQualityControl) {
    return shouldShowPlaybackRate ||
        shouldShowAudioOnly ||
        shouldShowVideoQualityControl;
};
// Language ---------------------------------
/**
 * @param isPremium whether the player is premium
 * @param isCurrentSequenceAd whether the current sequence is ad
 * @param hasLanguages whether the content has languages
 * @returns whether language control is shown
 */
export var isLanguageControlShown = function (isPremium, isCurrentSequenceAd, hasLanguages) { return !!isPremium && !isCurrentSequenceAd && hasLanguages; };
/**
 * @param hasMultipleLanguagesOrSubtitles whether the content has multiples languages or subtitles
 * @param isModalTypeLanguage whether the type of the modal is a language
 * @returns whether language control is disabled
 */
export var isLanguageControlDisabled = function (hasMultipleLanguagesOrSubtitles, isModalTypeLanguage) { return !hasMultipleLanguagesOrSubtitles || isModalTypeLanguage; };
// Live ---------------------------------
/**
 * @param isPremium whether the player is premium
 * @param isLive whether the content is live
 * @param isCurrentSequenceAd whether the current sequence is ad
 * @returns whether live control is shown
 */
export var isLiveControlShown = function (isPremium, isLive, isCurrentSequenceAd) { return !!isPremium && isLive && !isCurrentSequenceAd; };
/**
 * @param isAntiAdSkipEnabled whether antiAdSkip is enabled
 * @param isAdAntiSkip whether the ad is antiAdSkip
 * @param timeRemainingInAdSequence the time remaining in current ad sequence
 * @param isAntiAdSkipSequenceActive whether the antiAdSkip sequence is active
 * @returns whether live ads control is shown
 */
export var isLiveAdsControlShown = function (isAntiAdSkipEnabled, isAdAntiSkip, timeRemainingInAdSequence, isAntiAdSkipSequenceActive) {
    return !!isAntiAdSkipEnabled &&
        !!isAdAntiSkip &&
        timeRemainingInAdSequence > 0 &&
        isAntiAdSkipSequenceActive;
};
/**
 * @param isLiveEdge whether is live edge
 * @param isStopped whether player is stopped
 * @returns whether live control is disabled
 */
export var isLiveControlDisabled = function (isLiveEdge, isStopped) { return isLiveEdge || isStopped; };
// Next Episode ---------------------------------
/**
 * @param fetchContentSeries whether the series content is fetch
 * @param isLive whether the content is live
 * @param isPremium whether the player is premium
 * @param isCurrentSequenceAd whether the current sequence is ad
 * @param hasCurrentSeasonData whether there is current season data
 * @param hasNextEpisode whether there us next episode
 * @returns whether next episode control is shown
 */
export var isNextEpisodeControlShown = function (fetchContentSeries, isLive, isPremium, isCurrentSequenceAd, hasCurrentSeasonData, hasNextEpisode) {
    return !!isPremium &&
        !isCurrentSequenceAd &&
        fetchContentSeries &&
        !isLive &&
        hasCurrentSeasonData &&
        hasNextEpisode;
};
// Seek Minus/Plus ---------------------------------
/**
 * @param isPremium whether the player is premium
 * @param isCurrentSequenceAd whether the current sequence is ad
 * @param canStartOver whether we could start over on the content
 * @returns whether seek control is shown
 */
export var isSeekControlShown = function (isPremium, isCurrentSequenceAd, canStartOver) { return !!isPremium && !isCurrentSequenceAd && canStartOver; };
// PiP ---------------------------------
/**
 * @param isPiPEnabled whether the pip is enabled
 * @param isPipAvailable whether the pip is available
 * @param isCurrentSequencePipEnabled whether the current sequence is pip enabled
 * @param isIphone whether the device is iphone
 * @param isCastActive whether chromecast is active
 * @returns whether pip control is shown
 */
export var isPipControlShown = function (isPiPEnabled, isPipAvailable, isCurrentSequencePipEnabled, isIphone, isCastActive) {
    return !!(isPiPEnabled &&
        isPipAvailable &&
        isCurrentSequencePipEnabled &&
        !isIphone &&
        !isCastActive);
};
// Program ---------------------------------
/**
 * @param isExpertModeAvailable whether expert mode is available
 * @param isLiveEdge whether is live edge
 * @param isLive whether the content is live
 * @param hasCurrentProgramDetails whether the curret program has details
 * @param hasData whether the curret program has data
 * @returns whether program control is shown
 */
export var isProgramControlDisabled = function (isExpertModeAvailable, isLiveEdge, hasData) { return (isExpertModeAvailable ? !isLiveEdge : !hasData); };
// Series ---------------------------------
/**
 * @param itemType program itemType
 * @param fetchContentSeries whether the series content is fetch
 * @param hasCurrentSeasonData whether there is current season data
 * @param series content type series
 * @param program content type program
 * @returns whether series control is shown
 */
export var isSeriesControlShown = function (itemType, fetchContentSeries, hasCurrentSeasonData, series, program) {
    return (itemType === series || itemType === program) &&
        fetchContentSeries &&
        hasCurrentSeasonData;
};
// Timeline ---------------------------------
/**
 * @param isLive whether the content is live
 * @returns whether timeline control is shown
 */
export var isTimelineControlShown = function (isLive) { return isLive; };
// Video Quality ---------------------------------
/**
 * Do not show on TV interface
 * @param isTvPlayer whether is TV player
 * @param isBookendScreen whether is Bookend Screen
 * @param isCastActive whether chromecast is active
 * @returns whether video quality control is shown
 */
export var isVideoQualityControlShown = function (isTvPlayer, isBookendScreen, isCastActive) { return !isTvPlayer && !isBookendScreen && !isCastActive; };
// Zaplist ---------------------------------
/**
 * @param isLive whether the content is live
 * @returns whether zaplist control is shown
 */
export var isZaplistControlShown = function (isLive) { return isLive; };
