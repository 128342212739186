import { createSelector } from 'reselect';
import { BusinessTypes } from '@canalplus/oneplayer-constants';
var _a = BusinessTypes.MODAL_TYPES, LANGUAGE = _a.LANGUAGE, CONTENT_DISRUPTING_MODAL = _a.CONTENT_DISRUPTING_MODAL, AUDIO_ONLY = _a.AUDIO_ONLY, PLAYBACK_RATE = _a.PLAYBACK_RATE, SETTINGS = _a.SETTINGS, QUALITY_CONTROL = _a.QUALITY_CONTROL, PARENTAL_CODE = _a.PARENTAL_CODE;
export var uiManagerSelector = function (state) {
    return state.uiManager;
};
export var displayStateSelector = createSelector(uiManagerSelector, function (uiManager) { return uiManager.displayState; });
export var modalStateSelector = createSelector(uiManagerSelector, function (uiManager) { return uiManager.modalState; });
export var panelSelector = createSelector(displayStateSelector, function (displayState) { return displayState.panel; });
export var buttonsSelector = createSelector(displayStateSelector, function (displayState) { return displayState.buttons; });
export var panelParamsSelector = createSelector(displayStateSelector, function (displayState) { return displayState.panelParams; });
/**
 * @returns {function(object): boolean}
 */
export var isAModalOpenSelector = createSelector(displayStateSelector, function (displayState) { return displayState.modal !== null; });
/**
 * @returns {function(object): string}
 */
export var modalTypeSelector = createSelector(displayStateSelector, function (displayState) { return displayState.modal; });
/**
 * @returns {function(object): boolean}
 */
export var isCornerModalOpenedSelector = createSelector(modalTypeSelector, function (type) {
    return type === SETTINGS ||
        type === QUALITY_CONTROL ||
        type === PLAYBACK_RATE ||
        type === AUDIO_ONLY;
});
/**
 * @returns {function(object): object}
 */
export var modalToBeDisplayedAgainSelector = createSelector(modalStateSelector, function (modalState) { return modalState.toBeDisplayedAgain; });
/**
 * @returns {function(object): boolean}
 */
export var isModalTypeLanguageSelector = createSelector(modalTypeSelector, function (type) { return type === LANGUAGE; });
/**
 * @returns {function(object): boolean}
 */
export var isModalTypeParentalCodeSelector = createSelector(modalTypeSelector, function (modalType) { return modalType === PARENTAL_CODE; });
/**
 * @returns {function(object): boolean}
 */
export var isContentDisruptingModalToBeDisplayedAgainSelector = createSelector(modalToBeDisplayedAgainSelector, function (toBeDisplayedAgain) {
    return !!(toBeDisplayedAgain === null || toBeDisplayedAgain === void 0 ? void 0 : toBeDisplayedAgain[CONTENT_DISRUPTING_MODAL]);
});
export var modalParamsSelector = createSelector(displayStateSelector, function (displayState) { return displayState.modalParams; });
export var focusedElementSelector = createSelector(uiManagerSelector, function (uiManager) { return uiManager.focusedElement; });
// The navigationHistorySelector and navigationHistoryLastCommandSelector selector
// are only used in the uiManagerMiddleware to compute the display state, they shouldn't
// be used outside of it.
export var navigationHistorySelector = createSelector(uiManagerSelector, function (uiManager) { return uiManager.navigationHistory; });
export var navigationHistoryLastCommandSelector = createSelector(navigationHistorySelector, function (navigationHistory) { return navigationHistory[0]; });
