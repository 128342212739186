export const NO_ADS = 'NO_ADS';
export const NO_ADS_SUPPORTED = 'NO_ADS_SUPPORTED';
export const AD_BLOCKER_DETECTED = 'AD_BLOCKER_DETECTED';
export const PARSING_ADS_ERROR = 'PARSING_ADS_ERROR';
export const NO_PARSABLE_ADS = 'NO_PARSABLE_ADS';

export const ENCRYPTED = 'CRYPTED';
export const PARENTAL_CODE_REQUIRED = 'PARENTAL-CODE-REQUIRED';
export const PARENTAL_RATING_RESTRICTED_HOURS =
  'PARENTAL-RATING-RESTRICTED-HOURS';
export const UNAUTHORIZED_PARENTAL_RATING = 'UNAUTHORIZED-PARENTAL-RATING';
export const UNAUTHORIZED_RATING = 'UNAUTHORIZED_RATING';
export const PARENTAL_RATING_RESTRICTED_DEVICE =
  'PARENTAL-RATING-RESTRICTED-DEVICE';
export const MEDIA_ERROR = 'MEDIA_ERROR';
export const ERROR_FROM_CORE_PLAYER = 'ERROR_FROM_CORE_PLAYER';
export const FAILED_LOADED_WITH_UHD_PLAYSET = 'FAILED_LOADED_WITH_UHD_PLAYSET';

export const UNAUTHORIZED_LIVE = 'UNAUTHORIZED-LIVE';
export const UNAUTHORIZED_STARTOVER = 'UNAUTHORIZED-STARTOVER';
export const UNAUTHORIZED_CHANNEL = 'UNAUTHORIZED-CHANNEL';
export const LIVE_RESTRICTED_DEVICE = 'LIVE-RESTRICTED-DEVICE';

export const MULTI_UNAUTHORIZED_CONTENT = 'MULTI_UNAUTHORIZED_CONTENT';

export const NO_STREAM_ERROR_CODES = [
  ENCRYPTED,
  PARENTAL_CODE_REQUIRED,
  PARENTAL_RATING_RESTRICTED_HOURS,
];
