var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable class-methods-use-this */
import ReactDOM from 'react-dom';
import { EnumPosition, } from '@oneplayer/types';
import Positioner from './Positioner';
import { ensurePositionsAreInside, getPositionRelativeToContainer, } from '../../../utils/positions';
var uiIndex = require('../../../assets/styles/layout.css').uiIndex;
var ElementPositioner = /** @class */ (function (_super) {
    __extends(ElementPositioner, _super);
    function ElementPositioner(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            left: undefined,
            top: undefined,
        };
        return _this;
    }
    ElementPositioner.getVerticalPosition = function (source, elementSize) {
        return source.top + source.height / 2 - elementSize.height / 2;
        // @todo: vertical mouse position if needed
    };
    ElementPositioner.prototype.componentDidMount = function () {
        var element = this.props.element;
        if (element) {
            this.update(element);
        }
    };
    ElementPositioner.prototype.componentDidUpdate = function (prevProps) {
        var _a;
        var _b = this.props, element = _b.element, mousePosition = _b.mousePosition;
        if (element && ((_a = prevProps === null || prevProps === void 0 ? void 0 : prevProps.mousePosition) === null || _a === void 0 ? void 0 : _a.x) !== (mousePosition === null || mousePosition === void 0 ? void 0 : mousePosition.x)) {
            this.update(element);
        }
    };
    ElementPositioner.prototype.componentWillUnmount = function () {
        if (this.updateRAF) {
            cancelAnimationFrame(this.updateRAF);
        }
    };
    ElementPositioner.prototype.getPosition = function () {
        var _a = this.state, left = _a.left, top = _a.top;
        return {
            left: left,
            top: top,
        };
    };
    ElementPositioner.prototype.getSource = function (element) {
        var width = element.width, height = element.height, offsetLeft = element.offsetLeft, offsetTop = element.offsetTop;
        return {
            top: offsetTop,
            left: offsetLeft,
            width: width,
            height: height,
        };
    };
    ElementPositioner.prototype.getElementSize = function () {
        var node = ReactDOM.findDOMNode(this);
        return {
            width: node.offsetWidth,
            height: node.offsetHeight,
        };
    };
    ElementPositioner.prototype.getHorizontalPosition = function (source, elementSize) {
        var _a = this.props, mousePosition = _a.mousePosition, frame = _a.frame;
        if (!mousePosition) {
            return source.left + source.width / 2 - elementSize.width / 2;
        }
        return (getPositionRelativeToContainer(mousePosition, frame).x -
            elementSize.width / 2);
    };
    ElementPositioner.prototype.positionate = function (source, elementSize) {
        var values;
        var _a = this.props, margin = _a.margin, frame = _a.frame, position = _a.position;
        switch (position) {
            case ElementPositioner.TOP:
                values = {
                    top: source.top - elementSize.height - margin,
                    left: this.getHorizontalPosition(source, elementSize),
                };
                break;
            case ElementPositioner.BOTTOM:
                values = {
                    top: source.top + source.height + margin,
                    left: this.getHorizontalPosition(source, elementSize),
                };
                break;
            case ElementPositioner.LEFT:
                values = {
                    top: ElementPositioner.getVerticalPosition(source, elementSize),
                    left: source.left - elementSize.width - margin,
                };
                break;
            case ElementPositioner.RIGHT:
                values = {
                    top: ElementPositioner.getVerticalPosition(source, elementSize),
                    left: source.left + source.width + margin,
                };
                break;
            default:
                values = {
                    left: 0,
                    top: 0,
                };
        }
        // Should not exceed from the container
        return ensurePositionsAreInside(__assign(__assign({}, values), elementSize), frame);
    };
    ElementPositioner.prototype.update = function (sourceElement) {
        var _this = this;
        this.updateRAF = requestAnimationFrame(function () {
            // hotfix: on ie11, the component unmounts before the RAF are executed
            try {
                // Update the element size
                var elementSize = _this.getElementSize();
                // Same position for source and contents
                var source = _this.getSource(sourceElement);
                // Final element position
                var elementPosition = _this.positionate(source, elementSize);
                // State positioning
                _this.setState({
                    left: elementPosition.left,
                    top: elementPosition.top,
                });
            }
            catch (e) {
                // continue regardless of error
            }
        });
    };
    ElementPositioner.TOP = EnumPosition.top;
    ElementPositioner.BOTTOM = EnumPosition.bottom;
    ElementPositioner.LEFT = EnumPosition.left;
    ElementPositioner.RIGHT = EnumPosition.right;
    ElementPositioner.positions = [
        ElementPositioner.TOP,
        ElementPositioner.BOTTOM,
        ElementPositioner.LEFT,
        ElementPositioner.RIGHT,
    ];
    ElementPositioner.defaultProps = {
        margin: 5,
        pointerEvents: 'auto',
        position: ElementPositioner.TOP,
        zIndex: parseInt(uiIndex + 1, 10),
    };
    return ElementPositioner;
}(Positioner));
export default ElementPositioner;
