var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from 'react';
import { EnumPosition } from '@oneplayer/types';
var styles = require('./Positioner.css');
var Positioner = /** @class */ (function (_super) {
    __extends(Positioner, _super);
    function Positioner() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Positioner.prototype.getPosition = function () {
        var _a = this.props, left = _a.left, top = _a.top;
        return {
            left: left,
            top: top,
        };
    };
    Positioner.prototype.render = function () {
        var _a = this.props, zIndex = _a.zIndex, children = _a.children, pointerEvents = _a.pointerEvents;
        var _b = this.getPosition(), left = _b.left, top = _b.top;
        var positionerStyle = {
            left: left,
            top: top,
            zIndex: zIndex,
            pointerEvents: pointerEvents,
        };
        /**
         * During the first rendering, the position is not yet defined.
         * But the tooltip should be rendered to calculate it's own width and height after.
         * It's positionated at [0,0] with an hidden visibility to avoid the glitch.
         */
        if (left === undefined || top === undefined) {
            positionerStyle.visibility = 'hidden';
        }
        return (_jsx("div", { style: positionerStyle, className: styles.positioner, children: children }));
    };
    return Positioner;
}(Component));
export default Positioner;
