import { BusinessTypes } from '@canalplus/oneplayer-constants';
import { TOfferZone } from '@canalplus/oneplayer-types';

import { fetchConfig } from '../../config';

const { DEVICE_TYPES } = BusinessTypes;

interface IDeviceEck {
  [DEVICE_TYPES.DESKTOP_MOBILE]: string;
  [DEVICE_TYPES.CHROMECAST]: string;
  // TODO: Remove optional typing ("?") of STB devices when Nagra will be implemented
  [DEVICE_TYPES.G9_TECHNICOLOR]?: string;
  [DEVICE_TYPES.G9L_TECHNICOLOR]?: string;
  [DEVICE_TYPES.G9MINI_HUMAX]?: string;
  [DEVICE_TYPES.G9MINIVE_HUMAX]?: string;
  [DEVICE_TYPES.G10_HUMAX]?: string;
  [DEVICE_TYPES.G10_SAGEMCOM]?: string;
  [DEVICE_TYPES.G10CPI_SAGEMCOM]?: string;
  [DEVICE_TYPES.G11]?: string;
  [DEVICE_TYPES.PLAYSTATION_4]: string;
  [DEVICE_TYPES.PLAYSTATION_5]: string;
  [DEVICE_TYPES.PHILIPS]: string;
  [DEVICE_TYPES.SAMSUNG]: string;
  [DEVICE_TYPES.LG]: string;
  [DEVICE_TYPES.MIB4]?: string;
  [DEVICE_TYPES.HISENSE]: string;
  [DEVICE_TYPES.FIRETVREACT]: string;
  [DEVICE_TYPES.TV_KEY]: string;
  [DEVICE_TYPES.UWP]: string;
  [DEVICE_TYPES.XBOX]: string;
}

const DEVICE_ECK: IDeviceEck = {
  [DEVICE_TYPES.DESKTOP_MOBILE]: 'pc',
  [DEVICE_TYPES.CHROMECAST]: 'chromecast',
  [DEVICE_TYPES.PLAYSTATION_4]: 'playstation',
  [DEVICE_TYPES.PLAYSTATION_5]: 'playstation',
  [DEVICE_TYPES.PHILIPS]: 'tvphilips',
  [DEVICE_TYPES.SAMSUNG]: 'tvsamsung',
  [DEVICE_TYPES.LG]: 'tvlg',
  [DEVICE_TYPES.HISENSE]: 'tvhisense',
  [DEVICE_TYPES.FIRETVREACT]: 'firetvreact',
  [DEVICE_TYPES.TV_KEY]: 'orangecletv',
  [DEVICE_TYPES.UWP]: 'uwp',
  [DEVICE_TYPES.XBOX]: 'xbox',
};

/**
 *
 * @param offerZone the zone of the user (eg. cpafra, cppol..)
 * @param deviceType type of the device
 * @returns a promise with the HAPI headers info
 */
async function getHapiHeadersInfo(
  offerZone: TOfferZone,
  deviceType: BusinessTypes.DEVICE_TYPES,
): Promise<{ 'xx-service': string; 'xx-operator': string | undefined }> {
  const serverConfig = await fetchConfig({
    env: 'prod',
    offerZone,
    deviceType,
  });
  return {
    'xx-service': serverConfig.hapi.service,
    'xx-operator': DEVICE_ECK[deviceType],
  };
}

export default getHapiHeadersInfo;
