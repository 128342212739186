// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[5].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js!../../../assets/styles/mycanal/colors.css");
var ___CSS_LOADER_ICSS_IMPORT_1___ = require("-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[5].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js!../../../assets/styles/telecomitalia/colors.css");
var ___CSS_LOADER_ICSS_IMPORT_2___ = require("-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[5].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js!../../../assets/styles/colors.css");
var ___CSS_LOADER_ICSS_IMPORT_3___ = require("-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[5].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js!../../../assets/styles/layout.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_2___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_3___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.i4vaQwYPiJ0FgBjR4ya1 {
  position: absolute;
  z-index: ${___CSS_LOADER_ICSS_IMPORT_3___.locals["uiIndex"]};
  box-sizing: border-box;
  width: 26px;
  height: 26px;
  margin: 15px 0 0 10px;
}

.ReEP0kthoJduTAaaX92l {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  pointer-events: auto;
  cursor: pointer;
  background: none;
  border: 2px solid ${___CSS_LOADER_ICSS_IMPORT_2___.locals["colorWhite"]};
  border-radius: 50%;
}

.Ry4csAN4CnmwnTpHnuQy {
  }

.Ry4csAN4CnmwnTpHnuQy:hover {
      background: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["actionColor--mycanal"]};
      border-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["actionColor--mycanal"]};
    }
.kbiI0tmfQ3jL4X4peNiz {
  }
.kbiI0tmfQ3jL4X4peNiz:hover {
      background: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["actionColor--mycanal_tvod"]};
      border-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["actionColor--mycanal_tvod"]};
    }
.ENGSkCtHUuwHDFhDzrvZ {
  }
.ENGSkCtHUuwHDFhDzrvZ:hover {
      background: ${___CSS_LOADER_ICSS_IMPORT_1___.locals["gradientActionColor--telecomitalia"]};
      border-color: ${___CSS_LOADER_ICSS_IMPORT_1___.locals["actionColor--telecomitalia"]};
    }
`, "",{"version":3,"sources":["webpack://./src/ui/components/presentational/exitbutton/ExitButton.css"],"names":[],"mappings":"AAKA;EACE,kBAAkB;EAClB,qDAAgB;EAChB,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,8DAA4B;EAC5B,kBAAkB;AACpB;;AAGE;EAOA;;AAJE;MACE,wDAA0B;MAC1B,0DAAoB;IACtB;AANF;EAOA;AAJE;MACE,wDAA0B;MAC1B,0DAAoB;IACtB;AANF;EAOA;AAJE;MACE,wDAA0B;MAC1B,0DAAoB;IACtB","sourcesContent":["@value actionColor--mycanal, actionColor--mycanal_tvod from \"../../../assets/styles/mycanal/colors.css\";\n@value actionColor--telecomitalia, gradientActionColor--telecomitalia from \"../../../assets/styles/telecomitalia/colors.css\";\n@value colorWhite from \"../../../assets/styles/colors.css\";\n@value uiIndex from \"../../../assets/styles/layout.css\";\n\n.exitButtonWrapper {\n  position: absolute;\n  z-index: uiIndex;\n  box-sizing: border-box;\n  width: 26px;\n  height: 26px;\n  margin: 15px 0 0 10px;\n}\n\n.exitButton {\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 28px;\n  height: 28px;\n  pointer-events: auto;\n  cursor: pointer;\n  background: none;\n  border: 2px solid colorWhite;\n  border-radius: 50%;\n}\n\n@define-mixin makeVariant $variant, $color, $gradientColor {\n  .exitButton--$(variant) {\n    composes: exitButton;\n\n    &:hover {\n      background: $gradientColor;\n      border-color: $color;\n    }\n  }\n}\n\n@mixin makeVariant mycanal, actionColor--mycanal, actionColor--mycanal;\n@mixin makeVariant mycanal_tvod, actionColor--mycanal_tvod,\n  actionColor--mycanal_tvod;\n@mixin makeVariant telecomitalia, actionColor--telecomitalia,\n  gradientActionColor--telecomitalia;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionColor--mycanal": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["actionColor--mycanal"]}`,
	"actionColor--mycanal_tvod": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["actionColor--mycanal_tvod"]}`,
	"actionColor--telecomitalia": `${___CSS_LOADER_ICSS_IMPORT_1___.locals["actionColor--telecomitalia"]}`,
	"gradientActionColor--telecomitalia": `${___CSS_LOADER_ICSS_IMPORT_1___.locals["gradientActionColor--telecomitalia"]}`,
	"colorWhite": `${___CSS_LOADER_ICSS_IMPORT_2___.locals["colorWhite"]}`,
	"uiIndex": `${___CSS_LOADER_ICSS_IMPORT_3___.locals["uiIndex"]}`,
	"exitButtonWrapper": `i4vaQwYPiJ0FgBjR4ya1`,
	"exitButton": `ReEP0kthoJduTAaaX92l`,
	"exitButton--mycanal": `Ry4csAN4CnmwnTpHnuQy ReEP0kthoJduTAaaX92l`,
	"exitButton--mycanal_tvod": `kbiI0tmfQ3jL4X4peNiz ReEP0kthoJduTAaaX92l`,
	"exitButton--telecomitalia": `ENGSkCtHUuwHDFhDzrvZ ReEP0kthoJduTAaaX92l`
};
module.exports = ___CSS_LOADER_EXPORT___;
