import { createSelector } from 'reselect';
import { isAntiAdSkipEnabledSelector } from './configSelector';
import { isLiveSelector, isPremiumSelector, vodChannelLogoUrlSelector, } from './dataSelector';
import { isCastActiveSelector, castMetadataSelector } from './castSelector';
export var channelSelector = function (state) {
    return state.channel;
};
/**
 * @returns array of all channels
 */
export var allChannelsSelector = createSelector(channelSelector, function (channel) { return channel.allChannels || []; });
/**
 * @returns {function(object): object}
 */
export var authorizedChannelSelector = createSelector(channelSelector, function (channel) { return channel.authorizedChannels || []; });
export var currentChannelSelector = createSelector(channelSelector, function (channel) { return channel.currentChannel; });
/**
 * @returns {function(object): boolean}
 */
export var isCurrentChannelAuthorizedSelector = createSelector(currentChannelSelector, function (currentChannel) { return !!(currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.isChannelAuthorized); });
/**
 * @returns {function(object): string}
 */
export var currentChannelEpgIdSelector = createSelector(currentChannelSelector, function (currentChannel) { return currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.epgId; });
/**
 * @returns {function(object): string}
 */
export var currentChannelNameSelector = createSelector(currentChannelSelector, function (currentChannel) { return currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.name; });
/**
 * @returns {function(object): string}
 */
export var currentChannelLogoUrlSelector = createSelector(currentChannelSelector, function (currentChannel) { return currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.logoUrlLight; });
/**
 * @returns {function(object): bool}
 */
export var isCurrentChannelCastableSelector = createSelector(currentChannelSelector, function (currentChannel) { return currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.isCastable; });
/**
 * @returns {function(object): bool}
 */
export var isCurrentChannelStartOverAuthorizedSelector = createSelector(currentChannelSelector, function (currentChannel) { return currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.isStartOverAuthorized; });
/**
 * @returns {function(object): bool}
 */
export var getGroupTypeCurrentChannelSelector = createSelector(currentChannelSelector, function (currentChannel) { return currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.groupType; });
/**
 * @returns {function(object): object}
 */
export var currentChannelManifestSelector = createSelector(currentChannelSelector, function (currentChannel) { return currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.manifest; });
/**
 * @returns {function(object): boolean}
 */
export var isCurrentChannelAdAntiSkipSelector = createSelector(currentChannelSelector, function (currentChannel) { return currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.isAdAntiSkip; });
/**
 * @returns duration of no seek for ad at the beginning of the program
 */
export var noSeekDurationForAdAtBeginningSelector = createSelector(currentChannelSelector, function (currentChannel) { return (currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.noSeekDurationForAdAtBeginning) || null; });
/**
 * @returns duration of no seek for ad duringg program
 */
export var noSeekDurationForAdAfterBeginningSelector = createSelector(currentChannelSelector, function (currentChannel) { return (currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.noSeekDurationForAdAfterBeginning) || null; });
/**
 *
 * @returns {function(object): boolean}
 */
export var isAntiAdSkipFeatureEnabledOnCurrentChannelSelector = createSelector(isAntiAdSkipEnabledSelector, isCurrentChannelAdAntiSkipSelector, noSeekDurationForAdAtBeginningSelector, noSeekDurationForAdAfterBeginningSelector, function (isAntiAdSkipEnabled, isAdAntiSkip, noSeekDurationForAdAtBeginning, noSeekDurationForAdAfterBeginning) {
    return isAntiAdSkipEnabled &&
        isAdAntiSkip &&
        (noSeekDurationForAdAtBeginning !== null ||
            noSeekDurationForAdAfterBeginning !== null);
});
/**
 * @returns {function(object): ?string}
 */
export var liveChannelLogoUrlSelector = createSelector(currentChannelLogoUrlSelector, isPremiumSelector, isCastActiveSelector, castMetadataSelector, function (currentChannelLogoUrl, isPremium, isCastActive, castMetadata) {
    var _a, _b;
    if (isCastActive) {
        return (_b = (_a = castMetadata.customData) === null || _a === void 0 ? void 0 : _a.channel) === null || _b === void 0 ? void 0 : _b.logoUrl;
    }
    return isPremium ? currentChannelLogoUrl : '';
});
/**
 * @returns {function(object): ?string}
 */
export var channelLogoUrlSelector = createSelector(isLiveSelector, vodChannelLogoUrlSelector, liveChannelLogoUrlSelector, function (isLive, vodChannelLogoUrl, liveChannelLogoUrl) {
    return (isLive ? liveChannelLogoUrl : vodChannelLogoUrl) || '';
});
/**
 * @returns {function(object): bool}
 */
export var isCastableSelector = createSelector(isPremiumSelector, isLiveSelector, isCurrentChannelCastableSelector, function (isPremium, isLive, isCurrentChannelCastable) {
    return !(isPremium && isLive && !isCurrentChannelCastable);
});
