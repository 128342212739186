/* eslint no-param-reassign: 0 */

export const getPlayerRootElement = (element) => {
  // eslint-disable-next-line no-cond-assign
  while ((element = element.parentElement)) {
    if (element.hasAttribute('player-root')) {
      return element;
    }
  }
  return window; // for safety: this will never be reached as long as arg is a child of player-root
};

export function getOffset(elem, offsetTop = 0, offsetLeft = 0) {
  if (!Number.isNaN(elem.offsetTop)) {
    offsetTop += elem.offsetTop;
  }
  if (!Number.isNaN(elem.offsetLeft)) {
    offsetLeft += elem.offsetLeft;
  }
  if (elem.offsetParent) {
    return getOffset(elem.offsetParent, offsetTop, offsetLeft);
  }
  return { offsetTop, offsetLeft };
}

export function getElementPositionAttributes(element) {
  const { width, height } = element.getBoundingClientRect();
  const root = getPlayerRootElement(element);

  const rootTop = root.getBoundingClientRect?.()?.top;
  const rootLeft = root.getBoundingClientRect?.()?.left;
  const { top: elementTop, left: elementLeft } =
    element.getBoundingClientRect?.();
  return {
    width,
    height,
    offsetLeft: elementLeft - rootLeft,
    offsetTop: elementTop - rootTop,
  };
}

export function getMousePosition(e) {
  return {
    x: e.pageX || e.clientX + window.pageXOffset,
    y: e.pageY || e.clientY + window.pageYOffset,
  };
}

function getTouchPosition(e) {
  return {
    x: e.touches[0].pageX,
    y: e.touches[0].pageY,
  };
}

function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints;
}

export function getPosition(e) {
  const hasTouch = isTouchDevice() && e.touches;
  return !hasTouch ? getMousePosition(e) : getTouchPosition(e);
}

/**
 * Ensure content's "left" and "top" positions are inside container
 */
export function ensurePositionsAreInside(content, container) {
  return {
    left: Math.min(Math.max(0, content.left), container.width - content.width),
    top: Math.min(Math.max(0, content.top), container.height - content.height),
  };
}

/**
 * Get a position inside a container
 */
export function getPositionRelativeToContainer(
  position,
  { offsetLeft, offsetTop },
) {
  const x = position?.x || 0;
  const y = position?.y || 0;

  return {
    x: x - offsetLeft + window.pageXOffset,
    y: y - offsetTop + window.pageYOffset,
  };
}
