import { mediaCapabilitiesProber } from 'rx-player/experimental/tools';
import { BusinessTypes, DrmTypes } from '@canalplus/oneplayer-constants';

import { TPlatform } from '@canalplus/oneplayer-types';
import { logger } from '../logger';
import { isNullOrUndefined } from '../object';
import {
  isFairplayDrmSupported,
  constructConfig,
  determineSupportedDrm,
  sortDrmContextList,
} from './utils';
import { ISupportedDRM } from './types';

const { FAIRPLAY_KEYSYSTEM } = DrmTypes;

enum HDCP_POLICY {
  SUPPORTED = 'Supported',
  NOT_SUPPORTED = 'NotSupported',
  UNKNOWN = 'Unknown',
}

/**
 * Create a single mediaKeys object for playready DRM
 * @returns mediaKeys
 */
export async function createPlayreadyMediaKeys(): Promise<MediaKeys> {
  if (isNullOrUndefined(navigator.requestMediaKeySystemAccess)) {
    logger.error('OnePlayer > requestMediaKeySystemAccess is not available');
    throw new Error(
      'probeHDCPPolicy > navigator.requestMediaKeySystemAccess is null or undefined',
    );
  }

  const keySystem = 'com.microsoft.playready';
  const drmConfig = {
    initDataTypes: ['cenc'],
    audioCapabilities: [
      {
        contentType: 'audio/mp4;codecs="mp4a.40.2"',
      },
    ],
    videoCapabilities: [
      {
        contentType: 'video/mp4;codecs="avc1.42E01E"',
      },
    ],
  };

  const mediaKeySystemAccess = await navigator.requestMediaKeySystemAccess(
    keySystem,
    [drmConfig],
  );
  return mediaKeySystemAccess.createMediaKeys();
}

/**
 * This method is very similar to the one used by the rx-player.
 * Unfortunately we can't use the one from the rx because it is based on a default drm,
 * and the result provided is not the same from the one provided with playready drm.
 * We might be able later to use the one from the rx if the drm chosen to call mediCapabilitiesProber API
 * is the same as the one given from the config. But this improvement is not so easy on the rx-player side.
 * @param hdcpVersion min required hdcp version
 * @param mediaKeys set of keys used for decryption
 * @returns is HDCP supported or not
 */
export default async function probeHDCPPolicy(
  hdcpVersion: string,
  mediaKeys: MediaKeys,
): Promise<HDCP_POLICY> {
  try {
    // Some MediaKeys can contain 'getStatusForPolicy' and some don't
    // (depending on browser, drm...)
    if (!('getStatusForPolicy' in mediaKeys)) {
      logger.error(
        'OnePlayer > error cannot call getStatusForPolicy from mediaKeys',
      );
      throw new Error('probeHDCPPolicy > getStatusForPolicy is not available');
    }
    const policy = { minHdcpVersion: hdcpVersion };
    const statusForPolicy = await (
      mediaKeys as unknown as {
        getStatusForPolicy: (policy: {
          minHdcpVersion: string;
        }) => Promise<MediaKeyStatus>;
      }
    ).getStatusForPolicy(policy);

    return statusForPolicy === 'usable'
      ? HDCP_POLICY.SUPPORTED
      : HDCP_POLICY.NOT_SUPPORTED;
  } catch (e) {
    return HDCP_POLICY.UNKNOWN;
  }
}

/**
 * Indicate which versions of HDCP is supported and return an array of versions supported in ascending order
 * based on playready drm.
 * @param hdcpVersions hdcp version to check
 * @returns is hdcp supported
 */
export async function isHDCPSupportedForPlayready(
  hdcpVersions: string[],
): Promise<typeof hdcpVersions> {
  let playreadyMediaKeys: MediaKeys;
  try {
    playreadyMediaKeys = await createPlayreadyMediaKeys();
  } catch (e) {
    logger.error('OnePlayer > cannot create media keys for HDCP check', e);
    return [];
  }
  const supportedVersions: typeof hdcpVersions = [];
  const hdcpSupportedVersions: Array<Promise<void>> = hdcpVersions.map(
    (hdcpVersion) => {
      return probeHDCPPolicy(hdcpVersion, playreadyMediaKeys)
        .then((result) => {
          if (result === HDCP_POLICY.SUPPORTED) {
            supportedVersions.push(hdcpVersion);
          }
        })
        .catch((e) => {
          logger.error('OnePlayer > error while checking HDCP support', e);
        });
    },
  );

  return Promise.all(hdcpSupportedVersions).then(() => supportedVersions);
}

/**
 * Get the drm to use for the current browser
 * @param deviceType device we are on
 * @param CENC_KEY_SYSTEMS_PRIORITY DRM array of priority
 * @param platform platform of the content
 * @param hdcpContext is hdcp versions are supported (check for 1.4 and 2.2)
 * @param shouldPersistLicense whether or not we should persist license
 * @returns array of drm supported by the browser ordred by priority
 */
export async function getDrmContext(
  deviceType: BusinessTypes.DEVICE_TYPES,
  CENC_KEY_SYSTEMS_PRIORITY?: string[] | undefined,
  platform?: TPlatform,
  hdcpContext: string[] | null = null,
  shouldPersistLicense?: boolean,
): Promise<ISupportedDRM[] | null> {
  if (isFairplayDrmSupported(deviceType)) {
    return [
      {
        keySystem: FAIRPLAY_KEYSYSTEM,
        name: 'fairplay',
        robustnesses: ['HW_SECURE_ALL'],
      },
    ];
  }

  try {
    mediaCapabilitiesProber.LogLevel = 'NONE';
    const drmConfigs =
      await mediaCapabilitiesProber.getCompatibleDRMConfigurations(
        constructConfig(deviceType, shouldPersistLicense),
      );
    const supportedCencDrms = determineSupportedDrm(
      drmConfigs.filter((config) => !!config),
    );
    if (supportedCencDrms.length === 0) {
      return null;
    }

    const keySystemConfig = sortDrmContextList({
      drmContextList: supportedCencDrms,
      deviceType,
      hdcpContext,
      CENC_KEY_SYSTEMS_PRIORITY,
      platform,
    });
    /**
     * Infortunately, that ugly and hacky condition is mandatory in order to play content on the PS5
     * That browser method on the MediaSource is handled by the low level players as the rx-player.
     * But we decided, that it's too specific to integrate it inside the rx-player.
     * So, for now, we must do that here.
     */
    if (
      keySystemConfig[0].keySystem ===
      'com.microsoft.playready.recommendation.esvm'
    ) {
      const oldAddSourceBuffer = MediaSource.prototype.addSourceBuffer;
      MediaSource.prototype.addSourceBuffer = function (type): SourceBuffer {
        return oldAddSourceBuffer.call(
          this,
          type.includes('esvm=true')
            ? type
            : `${type};esvm=true;pass_through=true`,
        );
      };
    }
    return keySystemConfig;
  } catch {
    return null;
  }
}
