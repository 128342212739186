import { createSelector } from 'reselect';
export var userSelector = function (state) { return state.user; };
/**
 * @returns {function(object): string}
 */
export var liveTokenSelector = createSelector(userSelector, function (user) { return user.liveToken; });
/**
 * @returns {function(object): boolean}
 */
export var hasStreamTokenSelector = createSelector(userSelector, function (user) { return Object.keys(user.streamToken).length !== 0; });
/**
 * @returns {function(object): object}
 */
export var parentalControlSelector = createSelector(userSelector, function (user) { return user.parentalControl; });
/**
 * @returns {function(object): boolean}
 */
export var isUnlockedSelector = createSelector(parentalControlSelector, function (parentalControl) { return parentalControl.unlocked; });
/**
 * @returns {function(object): ?string}
 */
export var parentalCodeStatusSelector = createSelector(parentalControlSelector, function (parentalControl) { return parentalControl.parentalCodeStatus; });
/**
 * @returns {function(object): object | null}
 */
export var parentalCodeUserDataSelector = createSelector(userSelector, function (user) { return user.parentalCodeUserData; });
/**
 * @returns {function(object): boolean | undefined }
 */
export var doesParentalCodeExistsSelector = createSelector(parentalCodeUserDataSelector, function (parentalCodeUserData) { return parentalCodeUserData === null || parentalCodeUserData === void 0 ? void 0 : parentalCodeUserData.exists; });
/**
 * @returns {function(object): boolean | undefined }
 */
export var isParentalCodeActivatedForUserOverloadableContentsSelector = createSelector(userSelector, function (user) {
    var _a;
    return (_a = user.parentalCodeUserData) === null || _a === void 0 ? void 0 : _a.isParentalCodeActivatedForUserOverloadableContents;
});
/**
 * @returns {function(object): ?string}
 */
export var passTokenSelector = createSelector(userSelector, function (user) { return user.passToken; });
export var passIdSelector = createSelector(userSelector, function (user) { return user.passId; });
/**
 * @returns {function(object): ?string}
 */
export var deviceKeyIdSelector = createSelector(userSelector, function (user) { return user.deviceKeyId; });
/**
 * @returns {function(object): ?string}
 */
export var trackingKeyIdSelector = createSelector(userSelector, function (user) { return user.trackingKeyId; });
/**
 * @returns {function(object): ?string}
 */
export var microEligibilitySelector = createSelector(userSelector, function (user) { return user.microEligibility; });
/**
 * @returns {function(object): ?string}
 */
export var userKeyIdSelector = createSelector(userSelector, function (user) { return user.userKeyId; });
/**
 * @returns the user's profile id
 */
export var profileIdSelector = createSelector(userSelector, function (user) { return user.profileId; });
/**
 * @returns {function(object): ?string}
 */
export var appSessionIdSelector = createSelector(userSelector, function (user) { return user.appSessionId; });
/**
 * @returns {function(object): ?string}
 */
export var unlockedBroadcastIdSelector = createSelector(parentalControlSelector, function (parentalControl) { return parentalControl.unlockedBroadcastId; });
/**
 * @returns {function(object): ?string}
 */
export var abTestPopulationSelector = createSelector(userSelector, function (user) { return user.abTestPopulation; });
/**
 * @returns {function(object): string}
 */
export var tokenCmsSelector = createSelector(userSelector, function (user) { return user.tokenCMS; });
/**
 * @returns {function(object): string | null}
 */
export var parentalCodeCreationQrCodeUrlSelector = createSelector(userSelector, function (user) { return user.parentalCodeCreationQrCodeUrl; });
/**
 * @returns parental links
 */
export var userParentalLinksSelector = createSelector(userSelector, function (user) { return user.parentalLinks; });
