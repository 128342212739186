import { jsx as _jsx } from "react/jsx-runtime";
import { BusinessTypes } from '@canalplus/oneplayer-constants';
import withVariant from '../../../hoc/withVariant';
var MYCANAL = BusinessTypes.VARIANTS.MYCANAL;
var styles = require('./Spinner.css');
var Spinner = function (_a) {
    var _b = _a.variant, variant = _b === void 0 ? MYCANAL : _b, isSpinnerPositionBottom = _a.isSpinnerPositionBottom;
    return (_jsx("div", { className: isSpinnerPositionBottom ? styles.spinnerBottom : styles.spinnerCentered, "data-testid": "spinner", children: _jsx("span", { className: styles["circle--".concat(variant)] }) }));
};
// In order to separate concerns one would want to call and mount a spinner
// without having to know the rendering lib (here React)
var spinnerRender = function (root, variant) {
    root.render(_jsx(Spinner, { variant: variant }));
};
var spinnerUnmount = function (root) {
    root.unmount();
};
export default withVariant(Spinner);
export { Spinner, spinnerRender, spinnerUnmount };
